import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { connect } from 'react-redux'
import { daySummaryRequest } from '../../store/actions/daySummaryAction'
import AreaIcon from '../../assets/area_icon.svg'
import HourIcon from '../../assets/landing_time.svg'
import Distance from '../../assets/distance.svg'
import Speed from '../../assets/speedometer.svg'
import LastActive from '../../assets/lastActive.svg'
import Calendar from '../../assets/calendar.svg'
import Replay from '../../assets/Replay.svg'
import Skeleton from '@material-ui/lab/Skeleton'
import { Button } from '../../elements'
import { OutlinedButton } from '../../elements/button'
import Arrow from '../../assets/login/Long-arrow.svg'
import Pencil from '../../assets/orange_pen.svg'
import NoNetwork from '../../assets/no_network.svg'
import Vector from '../../assets/Vector.svg'
import AddGeofenceIcon from '../../assets/add_geofence.png'
import ListItem from '../ListTimeline'

import Swal from 'sweetalert2'
import {
    getTimeStatusDiff,
    getTimeDiff,
    formattedSlashDate,
    getTime,
} from '../../utils/TimeUtils'
import {
    getFuelMeterTank,
    getFuelMeterText,
} from '../../utils/getFuelMeterTank'
import tractorDescStyles from './tractordesc.styles'
import { checkPasswordForImmobilize, immobalizeDevice } from '../../api'
import { updateRequest } from '../../store/actions/updateTractorDataAction'
import { tractorDetailRequest } from '../../store/actions/tractorDetailAction'
import Noty from 'noty'

import Modal from '@material-ui/core/Modal'
import { localTractorNameAction } from '../../store/actions/localTractorNameAction'
import { Switch } from '@material-ui/core'

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { formattedDate } from '../../utils/TimeUtils'
import { tractorDetailReset } from '../../store/actions/tractorDetailAction'
import { tractorCompanyRequest } from '../../store/actions/tractorCompanyAction'
import { tractorModelRequest } from '../../store/actions/tractorModelAction'
import getTractorImages from '../../utils/getTractorImages'
import {
    getDayEventsRequest,
    getDayEventsReset,
} from '../../store/actions/getDayEventsAction'

import { getAllGeofenceRequest } from '../../store/actions/getAllGeofenceAction'
import {
    editGeofenceRequest,
    editGeofenceReset,
} from '../../store/actions/editGeofenceAction'

const variants = {
    open: {
        opacity: 1,
        width: '400px',
    },
    closed: {
        opacity: 0,
        width: '0px',
    },
}

function Tractordesc({
    selectedTractor: tractor,
    setShow,
    selectedRealtime: realTime,
    onShowTrips,
    onPositonChange,
    tractorList,
    realTimeList,
    showTrips,
    gromaxDevices,
    time,
    trac,
    drawingTool,
    setDrawingTool,
    showAreaButton,
    setShowAreaButton,
    shapes,
    setShapes,
    addGeofence,
    setAddGeofence,
    currentGeofence,
    setCurrentGeofence,
    newGeofence,
    setNewGeofence,
    showTracking,
    setShowTracking,
    ...props
}) {
    const classes = tractorDescStyles()
    let updateTractorRef = useRef(null)
    const tractorImage = getTractorImages(tractor.model, tractor.make)

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isGroMax, setIsGroMax] = useState(false)

    const [openTractorNameModal, setOpenTractorNameModal] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [timelineArray, setTimelineArray] = useState()

    const [geofenceFirst, setGeofenceFirst] = useState(false)
    const [geofenceSecond, setGeofenceSecond] = useState(false)
    const [geofenceToggleIndex, setGeofenceToggleIndex] = useState(null)
    const [isHovering, setIsHovering] = useState(false)

    const getName = () => {
        if (props.localTracName) {
            for (let i = 0; i < props.localTracName.length; i++) {
                if (props.localTracName[i].id === tractor.id) {
                    return props.localTracName[i].name.toLowerCase()
                }
            }
        }
        return tractor.name.toLowerCase()
    }

    const getCompany = () => {
        if (props.localTracName) {
            for (let i = 0; i < props.localTracName.length; i++) {
                if (props.localTracName[i].id === tractor.id) {
                    return props.localTracName[i].company
                }
            }
        }
        return tractor.make
    }

    const getModel = () => {
        if (props.localTracName) {
            for (let i = 0; i < props.localTracName.length; i++) {
                if (props.localTracName[i].id === tractor.id) {
                    return props.localTracName[i].model
                }
            }
        }
        return tractor.model
    }

    const [updateTractorName, setUpdateTractorName] = useState(getName())
    const [tractorCompany, setTractorCompany] = useState()
    const [selectCompanyName, setSelectCompanyName] = useState(getCompany())
    const [tractorModel, setTractorModel] = useState()
    const [selectCompanyModel, setSelectCompanyModel] = useState(getModel())

    useEffect(() => {
        let requestTractorId = {
            id: tractor.id,
        }
        let requestMakeId = {
            deviceId: tractor.device_pk,
        }
        props.tractorCompanySubmit({ requestMakeId })
        props.getAllGeofence({ requestTractorId })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.tractorCompany) {
            props.tractorCompany.data.sort((a, b) =>
                a.value > b.value ? 1 : -1
            )
            setTractorCompany(props.tractorCompany.data)
        }
    }, [props.tractorCompany])

    useEffect(() => {
        if (selectCompanyName) {
            let requestTractorModelId = {
                deviceId: tractor.device_pk,
                company: selectCompanyName,
            }
            props.tractorModelSubmit({ requestTractorModelId })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectCompanyName])

    useEffect(() => {
        if (props.tractorModel) {
            props.tractorModel.data.sort((a, b) => (a.value > b.value ? 1 : -1))
            setTractorModel(props.tractorModel.data)
        }
    }, [props.tractorModel])

    useEffect(() => {
        if (tractor) {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
            updateTractorRef.current = setInterval(() => {
                props.updateTractorRequest({ fb_key: tractor.fb_key })
            }, 2000)
        }
        return () => {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tractor])

    useEffect(() => {
        if (gromaxDevices && tractor) {
            if (gromaxDevices.includes(tractor.device_pk)) {
                setIsGroMax(true)
            } else {
                setIsGroMax(false)
            }
        }
    }, [gromaxDevices, tractor])

    useEffect(() => {
        if (props.dayEvents) {
            let checkArray = []
            for (let i = 0; i < props.dayEvents.data.length; i++) {
                checkArray.push({
                    title: props.dayEvents.data[i].event_title,
                    icon: props.dayEvents.data[i].event_icon_image,
                    time: getTime(props.dayEvents.data[i].time_stamp),
                })
            }
            if (checkArray && checkArray.length > 0) {
                setTimelineArray(checkArray)
            }
        }
    }, [props.dayEvents])

    useEffect(() => {
        if (tractor !== null) {
            let requestDeviceID = {
                device_id: tractor.device_pk,
                date: formattedDate(selectedDate),
                device_type: 'fleet',
            }
            if ((tractor.fleet_feature_enables & 1) === 1) {
                props.dayEventsSubmit({ requestDeviceID })
            }
            let timeStamp = Date.parse(selectedDate)
            if (!isNaN(timeStamp)) {
                props.daySummary({
                    tractor,
                    realTime,
                    date: formattedDate(selectedDate),
                })
            }
            if (showTrips) {
                handleShowTrips()
            }
        }
    }, [selectedDate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showTrips) {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
            updateTractorRef.current = setInterval(() => {
                props.updateTractorRequest({ fb_key: tractor.fb_key })
            }, 2000)
        }
        return () => {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTrips])

    useEffect(() => {
        if (!addGeofence) {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
            updateTractorRef.current = setInterval(() => {
                props.updateTractorRequest({ fb_key: tractor.fb_key })
            }, 2000)
        }
        return () => {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addGeofence])

    useEffect(() => {
        if (!newGeofence) {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
            updateTractorRef.current = setInterval(() => {
                props.updateTractorRequest({ fb_key: tractor.fb_key })
            }, 2000)
        }
        return () => {
            if (updateTractorRef.current) {
                clearInterval(updateTractorRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newGeofence])

    useEffect(() => {
        if (props.allGeofenceData?.status) {
            setGeofenceFirst(props.allGeofenceData?.data?.[0]?.['isLive'])
            setGeofenceSecond(props.allGeofenceData?.data?.[1]?.['isLive'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.allGeofenceData])

    useEffect(() => {
        if (geofenceToggleIndex !== null) {
            let requestEditGeofence = {
                geo_id: props.allGeofenceData?.data?.[geofenceToggleIndex]?.[
                    'geo_id'
                ],
                lat: props.allGeofenceData?.data?.[geofenceToggleIndex]?.[
                    'lat'
                ],
                lng: props.allGeofenceData?.data?.[geofenceToggleIndex]?.[
                    'lng'
                ],
                radius: props.allGeofenceData?.data?.[geofenceToggleIndex]?.[
                    'radius'
                ], // kilometers
                status: props.allGeofenceData?.data?.[geofenceToggleIndex]?.[
                    'status'
                ],
                isLive:
                    geofenceToggleIndex === 0 ? geofenceFirst : geofenceSecond,
                name: props.allGeofenceData?.data?.[geofenceToggleIndex]?.[
                    'name'
                ],
                pattern:
                    props.allGeofenceData?.data?.[geofenceToggleIndex]?.[
                        'pattern'
                    ],
                id: tractor.id,
            }
            props.editGeofenceSubmit({ requestEditGeofence })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geofenceToggleIndex])

    useEffect(() => {
        if (
            props.tractorUpdateMessage &&
            props.tractorUpdateMessage.status === true
        ) {
            new Noty({
                text: props.tractorUpdateMessage.message,
                layout: 'topRight',
                theme: 'bootstrap-v4',
                type: 'success',
                progressBar: true,
                timeout: 3000,
            }).show()

            setTimeout(() => {
                props.resetTractorUpdate()
            }, 3200)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tractorUpdateMessage])

    useEffect(() => {
        if (props.editGeofenceData?.status === true) {
            new Noty({
                text: 'Geofence actions executed successfully.',
                layout: 'topRight',
                theme: 'bootstrap-v4',
                type: 'success',
                progressBar: true,
                timeout: 3000,
            }).show()

            let requestTractorId = {
                id: tractor.id,
            }
            props.getAllGeofence({ requestTractorId })
            setTimeout(() => {
                props.resetEditGeofence()
            }, 3200)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editGeofenceData])

    const renderUpdatedDays = (
        date,
        selectedDate,
        dayInCurrentMonth,
        dayComponent
    ) => {
        let parsedDate = formattedDate(date)
        if (props.dates.includes(parsedDate) && dayInCurrentMonth) {
            return (
                <div className={classes.dayWithDotContainer}>
                    {dayComponent}
                    <div className={classes.dayWithDot} />
                </div>
            )
        } else {
            return dayComponent
        }
    }

    const disablePastDates = () => {
        const firstDate = new Date(props.dates[0])

        let pastDate = new Date()
        pastDate.setDate(pastDate.getDate() - 180)
        if (firstDate < pastDate) {
            return pastDate
        } else {
            return firstDate
        }
    }
    const handleDateChange = (date) => {
        setSelectedDate(date)
        if (showTrips) {
            handleShowTrips()
            setShowAreaButton(false)
            if (shapes && shapes.length > 0) {
                for (let i = 0; i < shapes.length; i++) {
                    shapes[i].overlay.setMap(null)
                }
            }
            if (drawingTool) {
                drawingTool.setMap(null)
            }
        }
        setShowCalendar(false)
    }

    const handleShowTrips = () => {
        if (updateTractorRef.current) {
            clearInterval(updateTractorRef.current)
            updateTractorRef.current = null
        } else {
            updateTractorRef.current = setInterval(() => {
                props.updateTractorRequest({ fb_key: tractor.fb_key })
            }, 2000)
        }

        onShowTrips(!showTrips)
    }

    const lastActive = () => {
        for (let i = 0; i < time.length; i++) {
            if (time[i]['fb_key'] === realTime.fb_key) {
                return time[i]['time_stamp']
            }
        }
    }

    const getFuel = () => {
        for (let i = 0; i < time.length; i++) {
            if (time[i]['fb_key'] === realTime.fb_key) {
                return time[i]['fuel_level_dashboard']
            }
        }
    }

    const getSpeed = () => {
        for (let i = 0; i < time.length; i++) {
            if (time[i]['fb_key'] === realTime.fb_key) {
                return time[i]['speed']
            }
        }
    }

    const handleImmobilize = () => {
        Swal.fire({
            title: 'Enter Password',
            input: 'password',
            inputPlaceholder: 'Enter your password',
            inputLabel: 'Password',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: (password) => {
                return checkPasswordForImmobilize({ password: password })
                    .then((res) => {
                        if (!res.status) {
                            throw new Error(res.msg)
                        }
                        return res
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(
                            `Request failed: ${
                                error.msg ?? JSON.stringify(error)
                            }`
                        )
                    })
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                immobalizeDevice({ id: tractor.device_pk })
                    .then((res) => {
                        console.log(res)
                        Swal.fire({
                            icon: 'success',
                            title: `Hurrah!`,
                            text: `${res?.message}`,
                        })
                    })
                    .catch((e) => {
                        Swal.fire({
                            icon: 'error',
                            title: `oops!`,
                            text: `${e?.msg}`,
                        })
                        console.log(e)
                    })
            }
        })
    }

    const handleSubmit = () => {
        const requestTractorDetail = {
            name: updateTractorName === '' ? getName() : updateTractorName,
            manufacturer:
                selectCompanyName === '' ? getCompany() : selectCompanyName,
            model: selectCompanyModel === '' ? getModel() : selectCompanyModel,
            id: tractor.id,
            engine_hours: tractor.engine_hours,
            year: tractor.year,
            chassis: tractor.chassis,
            user_name: props.user_name,
            purchase_date: tractor.purchase_date,
            user_dob: props.user_dob,
        }

        const requestLocalTractorName = {
            name: updateTractorName === '' ? getName() : updateTractorName,
            company:
                selectCompanyName === '' ? getCompany() : selectCompanyName,
            model: selectCompanyModel === '' ? getModel() : selectCompanyModel,
            id: tractor.id,
        }

        props.updateTractorName({ requestLocalTractorName })
        props.updateTractorDetailRequest({ requestTractorDetail })
        setOpenTractorNameModal(!openTractorNameModal)
    }

    const checkEngineStatus = (stat) => {
        for (let i = 0; i < time.length; i++) {
            if (time[i]['fb_key'] === realTime.fb_key) {
                let value = getTimeStatusDiff(time[i]['time_stamp'])
                if ((time[i]['status'] & 1) === 1) {
                    if (value > 10) {
                        return classes.engineOut
                    } else {
                        return classes.engineOn
                    }
                } else if ((time[i]['status'] & 1) === 0) {
                    if (value > 120) {
                        return classes.engineOut
                    } else {
                        return classes.engineOff
                    }
                }
            }
        }
    }

    const checkEngine = () => {
        for (let i = 0; i < time.length; i++) {
            if (time[i]['fb_key'] === realTime.fb_key) {
                let value = getTimeStatusDiff(time[i]['time_stamp'])
                if ((time[i]['status'] & 1) === 1) {
                    if (value > 10) {
                        return classes.imgBorderOut
                    } else {
                        return classes.imgBorderOn
                    }
                } else if ((time[i]['status'] & 1) === 0) {
                    if (value > 120) {
                        return classes.imgBorderOut
                    } else {
                        return classes.imgBorderOff
                    }
                }
            }
        }
    }

    const checkEngineText = () => {
        for (let i = 0; i < time.length; i++) {
            if (time[i]['fb_key'] === realTime.fb_key) {
                let value = getTimeStatusDiff(time[i]['time_stamp'])
                if ((time[i]['status'] & 1) === 1) {
                    if (value > 10) {
                        return 'DIS'
                    } else {
                        return 'ON'
                    }
                } else if ((time[i]['status'] & 1) === 0) {
                    if (value > 120) {
                        return 'DIS'
                    } else {
                        return 'OFF'
                    }
                }
            }
        }
    }

    const handleNoDateClick = () => {
        setShowCalendar(true)
    }

    const handleGeofence = () => {
        if (updateTractorRef.current) {
            clearInterval(updateTractorRef.current)
            updateTractorRef.current = null
        } else {
            updateTractorRef.current = setInterval(() => {
                props.updateTractorRequest({ fb_key: tractor.fb_key })
            }, 2000)
        }

        setAddGeofence(!addGeofence)
    }

    const handleToggleGeofence = (index) => {
        if (index === 0) {
            setGeofenceFirst(!geofenceFirst)
            setGeofenceToggleIndex(index)
        } else if (index === 1) {
            setGeofenceSecond(!geofenceSecond)
            setGeofenceToggleIndex(index)
        }
    }

    const handleNewGeofence = () => {
        if (updateTractorRef.current) {
            clearInterval(updateTractorRef.current)
            updateTractorRef.current = null
        } else {
            updateTractorRef.current = setInterval(() => {
                props.updateTractorRequest({ fb_key: tractor.fb_key })
            }, 2000)
        }

        setNewGeofence(!newGeofence)
    }

    const handleMouseOver = () => {
        setIsHovering(true)
    }

    const handleMouseOut = () => {
        setIsHovering(false)
    }

    return (
        <motion.div
            transition={{ duration: 0.2 }}
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
            className={classes.tractorDesc}
        >
            <div className={classes.model}>
                <p>
                    {getCompany()} {getModel()}
                </p>
                {!showTracking && (
                    <span
                        onClick={() => {
                            setShow(true)
                            setNewGeofence(false)
                            setAddGeofence(false)
                            props.dayEventsReset()
                            onShowTrips(false)
                        }}
                    >
                        Close
                    </span>
                )}
            </div>
            <div className={classes.tractorDetail}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <div className={`${checkEngineStatus()}`}>
                        <div className={`${checkEngine()}`}>
                            <p>
                                {checkEngineText() === 'DIS' ? (
                                    <img
                                        src={NoNetwork}
                                        alt="No-Network"
                                        width="20px"
                                        height="20px"
                                    />
                                ) : (
                                    checkEngineText()
                                )}
                            </p>
                        </div>
                        <img
                            animate
                            src={
                                tractor.tractor_img_url !== null
                                    ? tractor.tractor_img_url
                                    : tractorImage
                            }
                            alt="tractor"
                            width="80px"
                        />
                    </div>
                    <div className={classes.tractorDescHeader}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <div style={{ fontWeight: '600' }}>{getName()}</div>
                            {localStorage.getItem('user_type') !== 'dealer' &&
                                !showTracking && (
                                    <div
                                        className={classes.edit}
                                        onClick={() =>
                                            setOpenTractorNameModal(
                                                !openTractorNameModal
                                            )
                                        }
                                    >
                                        <img src={Pencil} alt="pencil-icon" />
                                        Edit
                                    </div>
                                )}
                        </div>
                        <span>Device ID: {tractor.device_pk}</span>
                        <span style={{ fontSize: '12px', color: '#666' }}>
                            Subscription Due Date:{' '}
                            {tractor.subscription_due_date}
                        </span>
                    </div>
                </div>
                <div className={classes.summary}>
                    <div className={classes.split}>
                        <div className={classes.summaryBlock}>
                            <div className={classes.summaryIcon}>
                                <img
                                    src={getFuelMeterTank(
                                        tractor.fuel_range_params.end_fuel,
                                        getFuel()
                                    )}
                                    alt="hours"
                                />
                            </div>
                            <div className={classes.statusDescData}>
                                {props.summaryLoading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    <b>
                                        {getFuelMeterText(
                                            tractor.fuel_range_params.end_fuel,
                                            getFuel()
                                        )}
                                    </b>
                                )}
                                <p>Fuel Level</p>
                            </div>
                        </div>
                        <div className={classes.summaryBlock}>
                            <div className={classes.summaryIcon}>
                                <img src={Speed} alt="Speed" />
                            </div>
                            <div className={classes.statusDescData}>
                                <b>{getSpeed()} km/h</b>
                                <p>Speed</p>
                            </div>
                        </div>
                        <div className={classes.summaryBlock}>
                            <div className={classes.summaryIcon}>
                                <img src={LastActive} alt="last-active" />
                            </div>
                            <div className={classes.statusDescData}>
                                <b>{getTimeDiff(lastActive())}</b>
                                <p>Last Active</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '35px',
                    }}
                >
                    <span className={classes.tractorWorkHeaader}>
                        Tractor Work
                    </span>
                    {!showTracking && (
                        <span
                            className={classes.calendarHeader}
                            onClick={handleNoDateClick}
                        >
                            <img src={Calendar} alt="calendar" />
                            <span>
                                {formattedSlashDate(selectedDate) ===
                                formattedSlashDate(new Date())
                                    ? 'TODAY'
                                    : formattedSlashDate(selectedDate)}
                            </span>
                        </span>
                    )}
                </div>
                <div className={classes.summary}>
                    <div className={classes.split}>
                        <div className={classes.summaryBlock}>
                            <div className={classes.summaryIcon}>
                                <img src={HourIcon} alt="hours" />
                            </div>
                            <div className={classes.statusDescData}>
                                {props.summaryLoading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    <b>{props.totalTime}</b>
                                )}
                                <p>Worked</p>
                            </div>
                        </div>
                        <div className={classes.summaryBlock}>
                            <div className={classes.summaryIcon}>
                                <img src={AreaIcon} alt="area" />
                            </div>
                            <div className={classes.statusDescData}>
                                {props.summaryLoading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    <b>{props.area}</b>
                                )}
                                <p>Acres</p>
                            </div>
                        </div>
                        <div className={classes.summaryBlock}>
                            <div className={classes.summaryIcon}>
                                <img src={Distance} alt="distance" />
                            </div>
                            <div className={classes.statusDescData}>
                                {props.summaryLoading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    <b>{props.kms} KM</b>
                                )}
                                <p>Distance</p>
                            </div>
                        </div>
                    </div>
                </div>
                {showCalendar && (
                    <div className={classes.datePicker}>
                        {props.datesLoading ? (
                            <div
                                style={{
                                    width: '1%',
                                    margin: '0em',
                                    height: '0px',
                                }}
                            >
                                <Skeleton animation="wave" />
                            </div>
                        ) : (
                            <>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        onClose={() => setShowCalendar(false)}
                                        minDate={disablePastDates()}
                                        open={showCalendar}
                                        size="small"
                                        autoOk
                                        disableFuture
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        renderDay={renderUpdatedDays}
                                        disabled={
                                            !props.dates ||
                                            props.dates.length === 0
                                                ? true
                                                : false
                                        }
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </>
                        )}
                    </div>
                )}
                {!showTracking && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '35px',
                            marginBottom: '13px',
                        }}
                    >
                        <span className={classes.subHeader}>
                            {(tractor.fleet_feature_enables & 1) === 1
                                ? 'Timeline'
                                : ''}
                        </span>
                        <span
                            className={classes.replayButton}
                            onClick={
                                !props.dailyStatus ? () => {} : handleShowTrips
                            }
                        >
                            <img src={Replay} alt="replay" /> Trips Replay
                        </span>
                    </div>
                )}
                {(tractor.fleet_feature_enables & 1) === 1 && (
                    <ListItem
                        title={
                            timelineArray && timelineArray.length > 0
                                ? 'Timeline'
                                : 'Timeline - No Trips'
                        }
                        content={timelineArray}
                    />
                )}
                {localStorage.getItem('user_type') !== 'dealer' &&
                    props.allGeofenceData?.status &&
                    !showTracking && (
                        <span className={classes.geofenceHeader}>Geofence</span>
                    )}
                {localStorage.getItem('user_type') !== 'dealer' &&
                    !showTracking &&
                    props.allGeofenceData?.status && (
                        <div className={classes.geofenceWrapper}>
                            {props.allGeofenceData?.data[0][
                                'is_default_geofence'
                            ] ? (
                                <div
                                    className={classes.geofenceNewTab}
                                    onClick={() => {
                                        setCurrentGeofence(0)
                                        handleNewGeofence()
                                    }}
                                >
                                    <img src={AddGeofenceIcon} alt="" />
                                    ADD GEOFENCE
                                </div>
                            ) : (
                                <div className={classes.geofenceTab}>
                                    <div className={classes.geofenceTabTop}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: '29px',
                                                    height: '29px',
                                                    borderRadius: '4px',
                                                    background: '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <img
                                                    src={Vector}
                                                    alt="today-stats-distance"
                                                    width="25px"
                                                    height="25px"
                                                />
                                            </span>
                                            <span>
                                                <b>
                                                    {
                                                        props.allGeofenceData
                                                            ?.data?.[0]?.[
                                                            'name'
                                                        ]
                                                    }
                                                </b>
                                            </span>
                                        </div>
                                        <Switch
                                            checked={geofenceFirst}
                                            onChange={() =>
                                                handleToggleGeofence(0)
                                            }
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    </div>
                                    {!isHovering ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onMouseOver={handleMouseOver}
                                            onMouseOut={handleMouseOut}
                                        >
                                            <span>
                                                Radius:{' '}
                                                <b>
                                                    {props.allGeofenceData?.data?.[0]?.[
                                                        'radius'
                                                    ]?.toFixed(2)}{' '}
                                                    Km
                                                </b>
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                height: '26px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: '#fff',
                                                borderRadius: '0px 0px 4px 4px',
                                                textTransform: 'uppercase',
                                            }}
                                            onMouseOver={handleMouseOver}
                                            onMouseOut={handleMouseOut}
                                            onClick={() => {
                                                setCurrentGeofence(0)
                                                handleGeofence()
                                            }}
                                        >
                                            <img
                                                src={Pencil}
                                                alt="pencil-icon"
                                            />
                                            Edit
                                        </div>
                                    )}
                                </div>
                            )}
                            {!props.allGeofenceData?.data[0][
                                'is_default_geofence'
                            ] && (
                                <>
                                    {props.allGeofenceData?.data[1][
                                        'is_default_geofence'
                                    ] ? (
                                        <div
                                            className={classes.geofenceNewTab}
                                            onClick={() => {
                                                setCurrentGeofence(1)
                                                handleNewGeofence()
                                            }}
                                        >
                                            <img src={AddGeofenceIcon} alt="" />
                                            ADD GEOFENCE
                                        </div>
                                    ) : (
                                        <div className={classes.geofenceTab}>
                                            <div
                                                className={
                                                    classes.geofenceTabTop
                                                }
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            width: '29px',
                                                            height: '29px',
                                                            borderRadius: '4px',
                                                            background: '#fff',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={Vector}
                                                            alt="today-stats-distance"
                                                            width="25px"
                                                            height="25px"
                                                        />
                                                    </span>
                                                    <span>
                                                        <b>
                                                            {
                                                                props
                                                                    .allGeofenceData
                                                                    ?.data?.[1]?.[
                                                                    'name'
                                                                ]
                                                            }
                                                        </b>
                                                    </span>
                                                </div>
                                                <Switch
                                                    checked={geofenceSecond}
                                                    onChange={() =>
                                                        handleToggleGeofence(1)
                                                    }
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label':
                                                            'controlled',
                                                    }}
                                                />
                                            </div>
                                            {!isHovering ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    onMouseOver={
                                                        handleMouseOver
                                                    }
                                                    onMouseOut={handleMouseOut}
                                                >
                                                    <span>
                                                        Radius:{' '}
                                                        <b>
                                                            {props.allGeofenceData?.data?.[1]?.[
                                                                'radius'
                                                            ]?.toFixed(2)}{' '}
                                                            Km
                                                        </b>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        height: '26px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        background: '#fff',
                                                        borderRadius:
                                                            '0px 0px 4px 4px',
                                                        textTransform:
                                                            'uppercase',
                                                    }}
                                                    onMouseOver={
                                                        handleMouseOver
                                                    }
                                                    onMouseOut={handleMouseOut}
                                                    onClick={() => {
                                                        setCurrentGeofence(1)
                                                        handleGeofence()
                                                    }}
                                                >
                                                    <img
                                                        src={Pencil}
                                                        alt="pencil-icon"
                                                    />
                                                    Edit
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                {openTractorNameModal && (
                    <Modal
                        open={openTractorNameModal}
                        onClose={() =>
                            setOpenTractorNameModal(!openTractorNameModal)
                        }
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div className={classes.filterOptions}>
                            <div className={classes.filterOptionsHeader}>
                                <span>
                                    <b>Update Tractor Details:</b>
                                </span>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectCompanyName(getCompany())
                                        setSelectCompanyModel(getModel())
                                        setUpdateTractorName(getName())
                                        setOpenTractorNameModal(
                                            !openTractorNameModal
                                        )
                                    }}
                                >
                                    Close
                                </span>
                            </div>
                            <select
                                value={selectCompanyName}
                                onChange={(e) =>
                                    setSelectCompanyName(e.target.value)
                                }
                                className={classes.inputType}
                            >
                                <option value="">
                                    {' '}
                                    Select Tractor Company
                                </option>
                                {tractorCompany &&
                                    tractorCompany.map((name, index) => (
                                        <option value={name} key={index}>
                                            {name}
                                        </option>
                                    ))}
                            </select>
                            <select
                                value={selectCompanyModel}
                                onChange={(e) =>
                                    setSelectCompanyModel(e.target.value)
                                }
                                className={classes.inputType}
                            >
                                <option value=""> Select Tractor Model</option>
                                {tractorModel &&
                                    tractorModel.map((name, index) => (
                                        <option value={name} key={index}>
                                            {name}
                                        </option>
                                    ))}
                            </select>
                            <input
                                type="text"
                                placeholder="Update Tractor name"
                                value={updateTractorName}
                                onChange={(e) =>
                                    setUpdateTractorName(e.target.value)
                                }
                                className={classes.inputType}
                            />
                            <div style={{ marginTop: '20px' }}>
                                <Button
                                    type="button"
                                    onClick={handleSubmit}
                                    width="100%"
                                >
                                    <p>Update</p>
                                    <span>
                                        <img src={Arrow} alt="arrow" />
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
                <div className={classes.showTrips}>
                    {isGroMax && (
                        <OutlinedButton
                            type="button"
                            width="100%"
                            onClick={handleImmobilize}
                        >
                            <p style={{ fontWeight: 'bold' }}>Immobilize</p>
                        </OutlinedButton>
                    )}
                </div>
            </div>
        </motion.div>
    )
}

const mapStateToProps = (state) => ({
    datesLoading: state.tractorReducer.loading,
    selectedTractor: state.tractorReducer.tractor,
    selectedRealtime: state.tractorReducer.realTime,
    dates: state.tractorReducer.dates,
    selectedDate: state.dateReducer.date,
    summaryLoading: state.daySummaryReducer.loading,
    dailyStatus: state.daySummaryReducer.dailyStatus,
    area: state.daySummaryReducer.area,
    totalTime: state.daySummaryReducer.totalTime,
    kms: state.daySummaryReducer.kms,
    engineStatus: state.daySummaryReducer.status,
    fuelLevel: state.daySummaryReducer.fuelLevel,
    gromaxDevices: state.gromaxReducer.data,
    commutes: state.daySummaryReducer.commutes,
    patches: state.daySummaryReducer.patches,
    time: state.loginReducer.realTimes,
    trac: state.loginReducer.tractors,
    user_name: state.loginReducer.name,
    user_dob: state.loginReducer.dob,
    tractorUpdateMessage: state.tractorUpdateReducer.tractorDataUpdated,
    localTracName: state.localTractorNameReducer.localTractorNameList,
    patchArea: state.daySummaryReducer.patchesArea,
    tractorCompany: state.tractorCompanyReducer.resTractorCompany,
    tractorModel: state.tractorModelReducer.resTractorModel,
    dayEvents: state.getDayEventsReducer.resAllEvents,
    dealerHandle: state.otpRequestReducer.otpSuccess,
    allGeofenceData: state.getAllGeofenceReducer.resAllGeofence,
    editGeofenceData: state.editGeofenceReducer.resEditGeofence,
})

const mapDispatchToProps = (dispatch) => ({
    daySummary: ({ realTime, tractor, date }) => {
        dispatch(daySummaryRequest({ realTime, tractor, date }))
    },
    updateTractorRequest: ({ fb_key }) => {
        dispatch(updateRequest({ fb_key }))
    },
    updateTractorDetailRequest: ({ requestTractorDetail }) => {
        dispatch(tractorDetailRequest({ requestTractorDetail }))
    },
    updateTractorName: ({ requestLocalTractorName }) => {
        dispatch(localTractorNameAction({ requestLocalTractorName }))
    },
    resetTractorUpdate: () => {
        dispatch(tractorDetailReset())
    },
    tractorCompanySubmit: ({ requestMakeId }) =>
        dispatch(tractorCompanyRequest({ requestMakeId })),
    tractorModelSubmit: ({ requestTractorModelId }) =>
        dispatch(tractorModelRequest({ requestTractorModelId })),
    dayEventsSubmit: ({ requestDeviceID }) =>
        dispatch(getDayEventsRequest({ requestDeviceID })),
    dayEventsReset: () => {
        dispatch(getDayEventsReset())
    },
    getAllGeofence: ({ requestTractorId }) =>
        dispatch(getAllGeofenceRequest({ requestTractorId })),
    editGeofenceSubmit: ({ requestEditGeofence }) =>
        dispatch(editGeofenceRequest({ requestEditGeofence })),
    resetEditGeofence: () => {
        dispatch(editGeofenceReset())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Tractordesc)
